import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import SessionScreen from './SessionScreen';
import VideoPlayer from '../../components/VideoPlayer'
import { useEffect } from 'react';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('82', t('screens:82.uuid'));
    navigate('/session/78');
  }

  useEffect(() => {
    props.useAudio('');
  });

  // async function goBack() {
  //   navigate(-1);
  // }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="82"
      title={`${t('screens:82.step')} ${t('screens:82.title')}`}
      chapter={1}
      progress={30}
      audio={t('screens:82.audio')}
      actions={actions}
    >
      <VideoPlayer src={t('screens:82.source')} poster={t('screens:82.poster')} />
    </SessionScreen>
  );
};
