import { ReactComponent as Visual } from '../../assets/body.svg';
import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import CheckField from '../../components/CheckField';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import Dialog from '../../components/Dialog';

type ValueKeys = 'head' | 'neck' | 'shoulders' | 'chest' | 'belly' | 'arms' | 'buttocks' | 'hands' | 'legs' | 'feet';

export type StoredValue = {
  [key in ValueKeys]: 'yes' | 'no';
}

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [checkedValues, setCheckedValue] = useState<StoredValue>({
    head: 'no',
    neck: 'no',
    shoulders: 'no',
    chest: 'no',
    belly: 'no',
    arms: 'no',
    buttocks: 'no',
    hands: 'no',
    legs: 'no',
    feet: 'no',
  });

  useEffect(() => {
    props.useAudio('screens:47.audio');
  });

  async function answer() {
    if (Object.values(checkedValues).filter(v => v === 'yes').length > 3) {
      return setErrorMessage(t('please_select_a_maximum_of_count_items', { count: 3 }));
    }

    await storeAnswer('47', t('screens:47.uuid'), checkedValues);

    if (Object.values(checkedValues).filter(v => v === 'yes').length === 0) {
      navigate('/session/48', { replace: true });
    } else {
      navigate('/session/84', { replace: true });
    }
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  const answers = [
    ['head'       ,      'neck'],
    ['shoulders'  ,     'chest'],
    ['belly'      ,      'arms'],
    ['buttocks'   ,     'hands'],
    ['legs'       ,      'feet'],
  ];

  function handleCheck(key: string, state: string): void {
    setCheckedValue({
      ...checkedValues,
      [key as keyof StoredValue]: state,
    });
  }

  return (
    <SessionScreen
      screen="47"
      title={`${t('screens:47.step')} ${t('screens:47.title')}`}
      chapter={2}
      progress={100}
      audio={t('screens:47.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:47.text')} />

      <div className="relative flex flex-col justify-between h-64 mt-10">
        <Visual className="h-64 absolute left-1/2 -translate-x-1/2" />

        {answers.map(([left, right], index) => {
          return (
            <div key={index} className="flex justify-between">
              <div className="flex gap-2">
                <CheckField id={left} label={t(left)} optionOn="yes" optionOff="no" value={checkedValues[left as keyof StoredValue]} onChange={(value) => handleCheck(left, value)} />
                <label htmlFor={left} className="font-bold">{t(left)}</label>
              </div>
              <div className="flex gap-2">
                <label htmlFor={right} className="font-bold">{t(right)}</label>
                <CheckField id={right} label={t(right)} optionOn="yes" optionOff="no" value={checkedValues[right as keyof StoredValue]} onChange={(value) => handleCheck(right, value)} />
              </div>
            </div>
          );
        })}
      </div>

      { errorMessage &&
        <Dialog actions={<Button onClick={() => setErrorMessage(null)} label={t('close')} />}>
          {errorMessage}
        </Dialog>
      }
    </SessionScreen>
  );
};
