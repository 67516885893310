import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import TextField from '../../components/TextField';
import useValidation from '../../hooks/useValidation';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const validation = useValidation(val, 'min:3');

  async function answer() {
    await storeAnswer('22', t('screens:22.uuid'), validation.value);
    navigate('/session/83', { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:22.audio');
  });

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={validation.failed} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="22"
      title={`${t('screens:22.step')} ${t('screens:22.title')}`}
      chapter={2}
      progress={45}
      audio={t('screens:22.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:22.text')} />
      <TextField value={val} onChange={setVal} maxLength={50} placeholder={t('write_your_answer_here')} />
    </SessionScreen>
  );
};
